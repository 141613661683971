import React from 'react'
import SEO from '~/components/seo'
import HomepageBanner from '~/components/ProductGrid/HomepageBanner'
import ProductGridHomepage from '~/components/ProductGrid/ProductGridHomepage'
import { Link } from 'gatsby'
import arrow from '../components/Navigation/down-chevron.svg'

// import banner from '../images/banner.jpeg'

const IndexPage = () => (
  <div>
    <SEO
      title="Home"
      keywords={[
        `coverful`,
        `cushion`,
        `cushion covers`,
        `uk`,
        `designs`,
        `bold`,
        `unique`,
        `interesting`,
      ]}
    />
    <HomepageBanner />
    <div
      className="heading-1"
      style={{
        // fontSize: 40,
        marginTop: 96,
        marginBottom: 48,
        fontFamily: 'AvenirBold',
      }}
    >
      New In
    </div>

    <ProductGridHomepage />
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 64 }}>
      <Link to={'/categories'}>
        <div
          // style={{
          //   position: 'relative',
          //   // bottom: 28,
          //   padding: '8px 29px 5px 16px',
          //   border: '1px solid rgb(36,44,72)',
          //   borderRadius: '50px',
          //   color: 'rgb(36,44,72)',
          //   display: "inline-block",

          // }}
          className="pastel2"
          style={{
            background: 'rgba(111,128,186)',
            borderRadius: 30,
            color: 'white',
            border: 'none',
            padding: 2,
            cursor: 'pointer',
            fontSize: 15,
            fontWeight: '500',
            letterSpacing: 0.5,
            boxShadow: '0px 2px 2px 0px rgba(0,30,50,0.08)',
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              padding: '10px 18px 9px',
              borderRadius: 1000,
            }}
          >
            Browse by category
            {/* <img
            alt=""
            style={{
              transform: 'rotate(-90deg)',
              height: 9,
              marginLeft: 6,
              marginTop: 4,
              position: 'absolute',
            }}
            src={arrow}
          /> */}
          </div>
        </div>
      </Link>
    </div>
  </div>
)

export default IndexPage
